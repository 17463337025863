import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Element from 'element-ui'
import axios from "axios";
import "element-ui/lib/theme-chalk/index.css"

import "./axios"
import "./permission"
Vue.use(Element);
Vue.prototype.$axios = axios;
// Vue.prototype.$baseURL = window.location.protocol + "//" + window.location.hostname + ":8081";
Vue.prototype.$baseURL = "https://zktl.top";
// Vue.prototype.$baseURL = "https://test.zktl.top";

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
